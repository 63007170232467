<template>
  <div class="Ach">
    <div class="Achievement">
      <div class="Achievement_top">
        <i class="el-icon-s-home"></i>
        >{{xmname}}>成果展示
      </div>
      <div class="Achievement_center">
        <!-- 标题 -->
        <div class="center_top huanghang">{{xmname}}成果展示</div>
        <div class="border">
          <!-- 左边 -->
          <div class="left" style="position: relative;">
            <div class="left_name" style="  border-top: 2px solid #b5b5b5;">任务名称信息</div>
            <div class="leftList">
              <div class="leftListNoneGd" :style="lsliInfo&&lsliInfo.islicode?{height:'610px'}:''">
                <div class="zrw" @click="Select(item.id)" v-for="item in Task_name" :key="item.id">
                  <span class="t_name huanghang">{{item.childTaskName}}</span>
                  <span class="ts_" v-if="item.evaluate==0&&state==0" @click="close(item.id)">评价</span>
                  <span class="ts_1" v-if="item.evaluate==1">优秀</span>
                  <span class="ts_1" v-if="item.evaluate==2">一般</span>
                  <span class="ts_1" v-if="item.evaluate==3">差</span>
                  <span
                    class="ts_"
                    v-if="state==0"
                    @click="complaint(item.id,item.fzrId,item.jgUserId,item.childTaskName)"
                  >投诉</span>
                </div>
              </div>
            </div>
            <template v-if="lsliInfo">
              <div
                v-if="type==0&&state==1"
                class="subtask_btn"
                :class="{'btnWidth':lsliInfo.islicode}"
                @click="tapGlLsli"
              >{{lsliInfo.islicode?'ISLI资源关联':'更换ISLI关联资源'}}</div>
              <div class="subtask_img" v-if="lsliInfo.islicode">
                <img
                  :src="api.LoginURL.concat('/resources/trading/upload'+lsliInfo.isliQRImgPath.replace('/home/uploadFile',''))"
                  width="100%"
                />
              </div>
            </template>
          </div>
          <!-- <div class="xian"></div> -->
          <!-- 右边 -->
          <div class="right">
            <div class="right_voer">
              <div class="overflow">
                <!-- <div class="left_name huanghang">{{xmname}}</div> -->
                <div class="padin" v-show="type==0" v-for="(item,index) in son" :key="index">
                  <div v-for="(bb,aa) in son[index].son_son" :key="aa">
                    <span class="name">{{bb.key}} ：</span>
                    <span class="name_font huanghang">{{bb.name}}</span>
                  </div>
                  <div>
                    <span class="name">全文录入：</span>
                    <span class="name_font huanghang">{{item.leru}}</span>
                  </div>
                </div>
              </div>
              <div class="padin2 fujian">
                <div v-if="enclosure.length!=0">
                  <div class="name">附件：</div>
                  <span
                    class="enclosure"
                    @click="xiazai(item.url)"
                    v-for="(item,index) in enclosure"
                    :key="index"
                  >附件{{index+1}}</span>
                </div>
                <div v-if="type!=0">
                  <div class="name">在线全文查看：</div>
                  <span class="enclosure_name" @click="chakan(full_text)">{{full_text}}</span>
                  <span class="enclosure_content" v-html="content"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button plain class="wid" v-if="state==0&&payment==1" @click="plxz">下载</el-button>
        <el-button plain class="wid" v-if="state==0&&payment==0" @click="payments">支付</el-button>
        <el-button plain class="wid" @click="fanhui" v-if="state!=0">返回</el-button>
      </div>
    </div>
    <div class="tankuang" v-if="viable">
      <div class="shensu">
        <div class="heyue_top">
          <span class="yin1" @click="close1()"></span>
        </div>
        <div class="tyss">
          <ul>
            <!-- 点赞 -->
            <li>
              <span>
                <img
                  @click="youxiu"
                  :src="flag1== false ? require('@/assets/zan/youxiu01.png'): require('@/assets/zan/youxiu.png')"
                  class="pinjia cursor"
                />
                <!-- 未评 -->
                <!-- <img
                  src="~@/assets/zan/youxiu01.png"
                  class="pinjia cursor"
                />-->
                <!-- 已评 -->
                <!-- <img class="pinjia cursor" src="~@/assets/zan/youxiu.png" /> -->
              </span>
              <span class="v_top">优秀</span>
            </li>
            <!-- 一般 -->
            <li>
              <span>
                <!-- 未评 -->
                <img
                  @click="yiban"
                  class="pinjia cursor"
                  :src="flag2== false ? require('@/assets/zan/yiban01.png'):require('@/assets/zan/yiban.png')"
                />
                <!-- 已评 -->
                <!-- <img class="pinjia cursor" src="~@/assets/zan/yiban.png" /> -->
              </span>
              <span class="v_top">一般</span>
            </li>
            <!-- 差 -->
            <li>
              <span>
                <!-- 未评 -->
                <img
                  @click="cha"
                  class="pinjia cursor"
                  :src="flag3== false ? require('@/assets/zan/cha01.png'):require('@/assets/zan/cha.png')"
                />
                <!-- <img class="pinjia cursor" src="~@/assets/zan/cha01.png" /> -->
                <!-- 已评 -->
                <!-- <img class="pinjia cursor" src="~@/assets/zan/cha.png" /> -->
              </span>
              <span class="v_top">差</span>
            </li>
          </ul>
          <div>
            <el-button class="elbutton" @click="ok" plain round>确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { post, get } from "@/services/http.js";
import { formatZero, getTimeFormat } from "@/js/common/Resoures";
import { eventBus } from "@/js/common/eventBus";
import { QUERYED, UPDATE, UPDATES, INSERT } from "../../../services/dao";
export default {
  data() {
    return {
      content: "",
      full_text: "", //全文查看
      flag1: false,
      flag2: false,
      flag3: false,
      xmname: "", //项目名称
      Task_name: [],
      son: [],
      son_son: [],
      type: this.$route.query.type, ///类型
      enclosure: [],
      viable: false, ///是否显示评价框
      chidId: 0,
      state: this.$route.query.state,
      payment: "", //是否支付
      money: "", // 支付金额（总酬劳）
      fzr_totalmoney: "", //负责人酬劳
      jgFzrAndTaskId: "", //负责人ID
      zong_renwu: [], //总任务
      lsliInfo: null //lsli信息
    };
  },
  created() {
    this.init();
  },
  methods: {
    chakan(adress) {
      window.open(adress, "_blank");
    },
    ///批量下载
    async plxz() {
      let childTaskIds = [];
      for (let j = 0; j < this.Task_name.length; j++) {
        childTaskIds.push(this.Task_name[j].id); //子任务酬劳
      }
      await post("/common/createPdf ", {
        taskId: this.$route.query.id, //大任务ID
        childTaskIds: childTaskIds, //所有子任务ID
        islicode: this.lsliInfo.islicode, //isli编码
        isliQRImgPath: this.lsliInfo.isliQRImgPath, //isli编码生成路径
        type: this.$route.query.type //任务类型
      })
        .then(e => {
          if (e.success) {
            if (e.data) {
              let formData = new FormData();
              formData.append("path", e.data);
              axios
                .post(
                  this.api.LoginURL.concat("/common/zsjgFileDown"),
                  formData,
                  {
                    responseType: "blob" //服务器返回的数据类型
                  }
                )
                .then(res => {
                  let read = new FileReader();
                  read.readAsDataURL(res.data);
                  read.onload = a => {
                    let fileName = "成果查看" + ".pdf";
                    let link = document.createElement("a"); //创建a标签
                    link.download = fileName; //a标签添加属性
                    link.href = a.target.result;
                    link.click(); //执行下载
                  };
                });
            } else {
              this.$message({
                showClose: true,
                message: "暂无可下载路径！",
                type: "warning"
              });
            }
          } else {
            this.$message({
              showClose: true,
              message: "下载失败！",
              type: "error"
            });
          }
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    ///支付
    async payments() {
      // console.log("this.Task_name", this.Task_name);
      let jiaqian = null;
      for (let j = 0; j < this.Task_name.length; j++) {
        jiaqian += Number(this.Task_name[j].childTaskMoney); //子任务酬劳
      }
      jiaqian += Number(this.fzr_totalmoney); //负责人酬劳
      this.$confirm(`是否确定支付${jiaqian}知识币？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        showClose: false,
        type: "warning"
      })
        .then(() => {
          this.agree(jiaqian); //确定
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确定
    async agree(jiaqian) {
      ////修改任务ID支付状态为已支付
      if (this.$route.query.type == 0) {
        let rwzt = await UPDATE(
          "post",
          "",
          "update_jgPeper(where: {id: {_eq: " +
            this.$route.query.id +
            "}}, _set: {payment  : " +
            1 +
            "}) { affected_rows  }"
        );
      }
      if (this.$route.query.type == 1) {
        let rwzt = await UPDATE(
          "post",
          "",
          "update_jgDigitalPro(where: {id: {_eq: " +
            this.$route.query.id +
            "}}, _set: {payment  : " +
            1 +
            "}) { affected_rows  }"
        );
      }
      if (this.$route.query.type == 2) {
        let rwzt = await UPDATE(
          "post",
          "",
          "update_jgEntryPro(where: {id: {_eq: " +
            this.$route.query.id +
            "}}, _set: {payment  : " +
            1 +
            "}) { affected_rows  }"
        );
      }
      // ///通过任务ID、类型、负责人id确定用户冻结酬劳
      ////修改发布者的账户余额
      // console.log("发布者ID",this.zong_renwu[0].userid);
      let fbz = await QUERYED(
        "POST",
        "",
        "User(where: {id  : {_eq: " +
          this.zong_renwu[0].userid +
          "}}) {Money zsjg_prepay }"
      );
      let balance = 0; //返回可用余额
      let frozen = 0; //返回冻结余额
      ///判断子任务所有酬劳是否等于总酬劳(jiaqian:所需，this.money：冻结)
      if (jiaqian == this.money) {
        ///冻结的价钱可以扣
        balance = Number(fbz.data.User[0].Money);
        frozen = Number(fbz.data.User[0].zsjg_prepay) - Number(jiaqian);
      } else if (jiaqian < this.money) {
        // 可用余额=原余额+（发布时扣的总酬劳-支付实际酬劳）
        balance =
          Number(fbz.data.User[0].Money) +
          (Number(this.money) - Number(jiaqian));
        // frozen = Number(fbz.data.User[0].zsjg_prepay) - Number(jiaqian);
        frozen = Number(fbz.data.User[0].zsjg_prepay) - Number(this.money);
      }
      // console.log(fbz.data.User[0].Money, fbz.data.User[0].zsjg_prepay);
      // console.log("可用余额", balance, "冻结余额", frozen);
      ///修改发布者的账户可用余额、冻结余额
      let fbz_money = await UPDATE(
        "post",
        "",
        "update_User(where: {id: {_eq: " +
          this.zong_renwu[0].userid +
          "}}, _set: {Money : " +
          balance +
          ",zsjg_prepay : " +
          frozen +
          "}) { affected_rows  }"
      );
      if (fbz_money.data.update_User.affected_rows == 1) {
        //修改成功后给发布者添加一条支出账单
        ////生成账单
        ////用户ID
        let ddata1 = await QUERYED(
          "post",
          "",
          "  WDALLBill(where: {userid: {_eq: " +
            this.zong_renwu[0].userid +
            "}}) {  time  orderNum  id   }"
        );
        // 生成扣除加工者账单的订单编号
        let orderNum1 = "";
        let num1 = String(formatZero(this.zong_renwu[0].userid, 7));
        /////生成订单编号
        if (ddata1.data.WDALLBill.length < 1) {
          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
        } else {
          for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
            if (
              getTimeFormat(new Date()) ==
              getTimeFormat(ddata1.data.WDALLBill[i].time)
            ) {
              let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
              orderNum1 =
                getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
            } else {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            }
          }
        }
        ////发布者的支出账单
        let zczd = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 4, state: 0, time: "' +
            new Date() +
            '", userid: ' +
            this.zong_renwu[0].userid +
            ', orderNum: "' +
            orderNum1 +
            '", orderid: ' +
            this.$route.query.id +
            ', Price: "' +
            jiaqian +
            '", evaluate: "",classification:1}) {affected_rows}'
        );
        if (zczd.data.insert_bill.affected_rows == 1) {
          // console.log("发布者账单生成成功！");
          await this.responsible(); //负责人扣费事件
          this.processor(jiaqian); //调用加工者事件
        }
      }
    },
    ////给负责人添加账单和消息通知
    async responsible() {
      let fzr = await QUERYED(
        "POST",
        "",
        "User(where: {id  : {_eq: " +
          this.jgFzrAndTaskId +
          "}}) {Money zsjg_prepay }"
      );
      let moeny = 0;
      moeny = Number(fzr.data.User[0].Money) + Number(this.fzr_totalmoney);
      let fzr_money = await UPDATE(
        "post",
        "",
        "update_User(where: {id: {_eq: " +
          this.jgFzrAndTaskId +
          "}}, _set: {Money : " +
          moeny +
          "}) { affected_rows  }"
      );
      if (fzr_money.data.update_User.affected_rows == 1) {
        //修改成功后给负责人添加一条支出账单
        ////生成账单
        ////用户ID
        let ddata1 = await QUERYED(
          "post",
          "",
          "  WDALLBill(where: {userid: {_eq: " +
            this.jgFzrAndTaskId +
            "}}) {  time  orderNum  id   }"
        );
        // 订单编号
        let orderNum1 = "";
        let num1 = String(formatZero(this.jgFzrAndTaskId, 7));
        /////生成订单编号
        if (ddata1.data.WDALLBill.length < 1) {
          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
        } else {
          for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
            if (
              getTimeFormat(new Date()) ==
              getTimeFormat(ddata1.data.WDALLBill[i].time)
            ) {
              let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
              orderNum1 =
                getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
            } else {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            }
          }
        }
        ////发布者的支出账单.getTime()
        let zczd = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 4, state: 0, time: "' +
            new Date() +
            '", userid: ' +
            this.jgFzrAndTaskId +
            ', orderNum: "' +
            orderNum1 +
            '", orderid: ' +
            this.$route.query.id +
            ', Price: "' +
            Number(this.fzr_totalmoney) +
            '", evaluate: "",classification:0}) {affected_rows}'
        );
        if (zczd.data.insert_bill.affected_rows == 1) {
          ///给负责人发送消息通知
          let fzr = await INSERT(
            "post",
            "",
            " insert_jgMessage(objects: {messageType:5,userId : " +
              this.jgFzrAndTaskId +
              ",userType: " +
              2 +
              ',taskName: "' +
              this.xmname +
              '",taskType: "' +
              3 +
              '",money: "' +
              Number(this.fzr_totalmoney) +
              '",fzrId: "' +
              this.zong_renwu[0].userid +
              '",fzrName: "' +
              this.zong_renwu[0].username +
              '",taskTime : "' +
              new Date().getTime() +
              '",taskTypeTow : ' +
              this.$route.query.type +
              "}) { affected_rows  }"
          );
          if (fzr.data.insert_jgMessage.affected_rows == 1) {
            console.log("负责人消息通知发送成功！");
          }
        }
      }
    },
    ///扣除子任务加工者的价钱并且发送消息通知
    async processor() {
      for (let j = 0; j < this.Task_name.length; j++) {
        ///循环扣除子任务的用户价格
        ////通过加工者ID查找加工者的账户可用余额
        let fbz = await QUERYED(
          "POST",
          "",
          "User(where: {id  : {_eq: " +
            this.Task_name[j].jgUserId +
            "}}) {Money zsjg_prepay }"
        );
        ///修改加工者的余额
        let yue =
          Number(fbz.data.User[0].Money) +
          Number(this.Task_name[j].childTaskMoney);
        ///修改发布者的账户可用余额
        let fbz_money = await UPDATE(
          "post",
          "",
          "update_User(where: {id: {_eq: " +
            this.Task_name[j].jgUserId +
            "}}, _set: {Money : " +
            yue +
            "}) { affected_rows  }"
        );
        if (fbz_money.data.update_User.affected_rows == 1) {
          //修改成功后给发布者添加一条支出账单
          ////生成账单
          ////用户ID
          let ddata1 = await QUERYED(
            "post",
            "",
            "  WDALLBill(where: {userid: {_eq: " +
              this.Task_name[j].jgUserId +
              "}}) {  time  orderNum  id   }"
          );
          // 生成扣除加工者账单的订单编号
          let orderNum1 = "";
          let num1 = String(formatZero(this.Task_name[j].jgUserId, 7));
          /////生成订单编号
          if (ddata1.data.WDALLBill.length < 1) {
            orderNum1 = getTimeFormat(new Date()) + num1 + "001";
          } else {
            for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
              if (
                getTimeFormat(new Date()) ==
                getTimeFormat(ddata1.data.WDALLBill[i].time)
              ) {
                let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
                orderNum1 =
                  getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
              } else {
                orderNum1 = getTimeFormat(new Date()) + num1 + "001";
              }
            }
          }
          ////我的支持账单.getTime()
          let zczd = await INSERT(
            "post",
            "",
            '  insert_bill(objects: {type: 4, state: 0, time: "' +
              new Date() +
              '", userid: ' +
              this.Task_name[j].jgUserId +
              ', orderNum: "' +
              orderNum1 +
              '", orderid: ' +
              this.$route.query.id +
              ', Price: "' +
              yue +
              '", evaluate: "",classification:1}) {affected_rows}'
          );
          if (zczd.data.insert_bill.affected_rows == 1) {
            // console.log("加工者账单生成成功！");
            ///给每个加工者发送一条消息通知
            let jgz = await INSERT(
              "post",
              "",
              " insert_jgMessage(objects: {messageType:5,userId : " +
                this.Task_name[j].jgUserId +
                ",userType: " +
                3 +
                ',taskName: "' +
                this.xmname +
                '",taskType: "' +
                3 +
                '",money: "' +
                Number(this.Task_name[j].childTaskMoney) +
                '",fzrId: "' +
                this.zong_renwu[0].userid +
                '",fzrName: "' +
                this.zong_renwu[0].username +
                '",taskTime : "' +
                new Date().getTime() +
                '",taskTypeTow : ' +
                this.$route.query.type +
                "}) { affected_rows  }"
            );
            console.log(jgz.data.insert_jgMessage.affected_rows);
            if (jgz.data.insert_jgMessage.affected_rows == 1) {
              console.log("加工者消息发送成功！");
              if (j == this.Task_name.length - 1) {
                this.$message({
                  message: "支付成功！",
                  type: "success"
                });
                this.init(); //刷新
              }
              //  else {
              //   this.$message.error("支付失败！");
              // }
            }
          }
        }
      }
    },
    fanhui() {
      this.$router.go(-1);
    },
    ///下载
    xiazai(url) {
      window.open(this.api.LoginURL.concat(url));
    },
    async Select(id) {
      if (this.$route.query.type == 0) {
        this.son = [];
        let qwlr = await QUERYED(
          "POST",
          "",
          "jgTypeIn(where: {childTaskId  : {_eq: " +
            id +
            "}}) {fullText jgPeperId  childTaskId imgUrl customEntryJson fullText fileList}"
        );
        // let obj = [];
        for (let i = 0; i < qwlr.data.jgTypeIn.length; i++) {
          let obj = [];
          for (let text of qwlr.data.jgTypeIn[i].customEntryJson.split(",")) {
            let arr = text.split(":");
            // obj[arr[0]] = arr[1];
            // console.log(arr);
            obj.push({ key: arr[0], name: arr[1] });
          }

          this.son.push({
            // id: info.data.jgChildTask[j].id,
            leru: qwlr.data.jgTypeIn[i].fullText,
            // customEntryJson: qwlr.data.jgTypeIn[i].customEntryJson,
            son_son: obj
          });
        }
      }
    },
    // 初始化
    async init() {
      this.enclosure = [];
      this.son = []; //清空
      this.Task_name = [];
      this.getJgrw(this.$route.query.id, this.$route.query.type);
      //  任务ID   this.$route.query.id   任务类型 this.$route.query.  taskType
      let info = await QUERYED(
        "POST",
        "",
        "JGSearch(where: {id: {_eq:" +
          this.$route.query.id +
          "},tasktype:{_eq:" +
          this.$route.query.type +
          "},jgFzrAndTaskId:{_eq:" +
          this.$route.query.fzrid +
          "}}) {taskname fzr_totalmoney  payment userid username jgFzrAndTaskId totalmoney }jgChildTask(order_by: {id: desc},where: {fzrId  : {_eq: " +
          this.$route.query.fzrid +
          "},taskId : {_eq: " +
          this.$route.query.id +
          "},taskType :{_eq:" +
          Number(Number(this.$route.query.type) + Number(1)) +
          "}}) {id taskId jgUserName  childTaskMoney  childTaskName fzrName evaluate fzrId jgUserId  drawTime  }"
      );
      this.zong_renwu = info.data.JGSearch; ///总任务信息
      this.jgFzrAndTaskId = info.data.JGSearch[0].jgFzrAndTaskId; //负责人ID
      this.fzr_totalmoney = info.data.JGSearch[0].fzr_totalmoney; //负责人酬劳
      this.money = info.data.JGSearch[0].totalmoney; //支付价格(总酬劳)
      this.payment = info.data.JGSearch[0].payment; //是否支付
      this.xmname = info.data.JGSearch[0].taskname;
      this.Task_name = info.data.jgChildTask;
      if (this.$route.query.type == 0) {
        ///右边赋值
        for (let j = 0; j < info.data.jgChildTask.length; j++) {
          ///通过id查找全文录入
          let qwlr = await QUERYED(
            "POST",
            "",
            "jgTypeIn(where: {childTaskId  : {_eq: " +
              info.data.jgChildTask[j].id +
              "}}) {fullText jgPeperId fileList  childTaskId imgUrl customEntryJson fullText fileList}"
          );
          for (let i = 0; i < qwlr.data.jgTypeIn.length; i++) {
            let obj = [];
            ///自定义录入项
            for (let text of qwlr.data.jgTypeIn[i].customEntryJson.split(",")) {
              let arr = text.split(":");
              // obj[arr[0]] = arr[1];
              // console.log(arr);
              obj.push({ key: arr[0], name: arr[1] });
            }

            // console.log("qwlr.data.jgTypeIn[i].fileList",qwlr.data.jgTypeIn[i].fileList);
            ////附件
            for (let fujian of qwlr.data.jgTypeIn[i].fileList.split(",")) {
              if (fujian) {
                let arr = fujian.split(":");

                this.enclosure.push({ url: arr }); //附件赋值\
              }
            }

            this.son.push({
              // id: info.data.jgChildTask[j].id,
              leru: qwlr.data.jgTypeIn[i].fullText,
              // customEntryJson: qwlr.data.jgTypeIn[i].customEntryJson,
              son_son: obj
            });
          }
        }
        // this.son.push({
        //   // id: info.data.jgChildTask[j].id,
        //   // name: info.data.jgChildTask[j].childTaskName, //任务名称
        //   // username: info.data.jgChildTask[j].jgUserName, //加工者
        //   // time: YearMMdd(Number(info.data.jgChildTask[j].drawTime)), //加工者时间
        //   leru: fullText
        //   // customEntryJson: qwlr.data.jgTypeIn[0].customEntryJson
        // });
      }
      if (this.$route.query.type == 2 || this.$route.query.type == 1) {
        const formData = new FormData();
        formData.append("taskId", this.$route.query.id);
        formData.append("taskType", this.$route.query.type);
        await post("/common/showResult", formData)
          .then(e => {
            this.full_text = e.data.resultPath; //全文路径赋值
            this.content = e.data.content;
            console.log("成功成功", e);
          })
          .catch(function(error) {
            console.log("错误" + error);
          });
      }
    },
    ///优秀
    youxiu() {
      this.flag1 = !this.flag1;
      this.flag2 = false;
      this.flag3 = false;
    },
    ///一般
    yiban() {
      this.flag2 = !this.flag2;
      this.flag1 = false;
      this.flag3 = false;
    }, ///差
    cha() {
      this.flag3 = !this.flag3;
      this.flag2 = false;
      this.flag1 = false;
    },
    ///确定提交
    async ok() {
      let tishi = "";
      let pj = 0;
      if (this.flag1 == true) {
        //优秀
        pj = 1;
        tishi = "优秀";
      }
      if (this.flag2 == true) {
        //一般
        pj = 2;
        tishi = "一般";
      }
      if (this.flag3 == true) {
        //差
        pj = 3;
        tishi = "差";
      }
      this.$confirm(`您对该任务的评价为${tishi}，确定提交吗？`, "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        showClose: false,
        type: "warning"
      })
        .then(() => {
          this.pinjia(pj); //确定
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    async pinjia(pj) {
      let xiugai = await UPDATES(
        "post",
        "",
        "   update_jgChildTask(where: {id: {_eq: " +
          this.chidId +
          "}}, _set: {evaluate : " +
          pj +
          "}) { affected_rows  }"
      );
      if (xiugai.data.update_jgChildTask.affected_rows == 1) {
        this.$message({
          message: "评价成功！",
          type: "success"
        });
        this.chidId = "";
        this.close1(); //关闭
        this.init();
      } else {
        this.$message({
          message: "评价失败！",
          type: "error"
        });
      }
    },
    // 关闭
    close1() {
      this.viable = false;
    },
    ///评价
    close(id) {
      console.log(this.payment);
      if (this.payment == 0) {
        this.$message({
          message: "该任务还未支付！",
          type: "warning"
        });
      } else if (this.payment == 1) {
        this.viable = !this.viable;
        this.flag3 = false;
        this.flag2 = false;
        this.flag1 = false;
        this.chidId = id; //子任务ID
        // console.log("子任务ID", this.chidId);
      }
    },
    //投诉
    complaint(id, fzrId, jgUserId, childTaskName) {
      this.$router.push({
        path: "/complaint",
        query: {
          id: id,
          fzrId: fzrId, //任务负责人
          jgUserId: jgUserId, //子任务加工者
          childTaskName: childTaskName ///子任务名称
        }
      });
    },

    //获取Lsli
    async getJgrw(id, type) {
      let sql = "jgPeper(where:{id:{_eq:" + id + "}})";
      if (type == 1) {
        sql = "jgDigitalPro(where:{id:{_eq:" + id + "}})";
      }
      if (type == 2) {
        sql = "jgEntryPro(where:{id:{_eq:" + id + "}})";
      }
      let info = await QUERYED("POST", "", sql + "{id islicode isliQRImgPath}");
      if (!info.success) {
        this.$message({
          message: "获取数据失败，请重试！",
          type: "warning"
        });
      }
      if (type == 0) {
        this.lsliInfo = info.data.jgPeper[0];
      }
      if (type == 1) {
        this.lsliInfo = info.data.jgDigitalPro[0];
      }
      if (type == 2) {
        this.lsliInfo = info.data.jgEntryPro[0];
      }
    },

    //跳转lsli关联
    tapGlLsli(code) {
      eventBus.$once("updateLsliInfo", () => {
        this.getJgrw(this.$route.query.id, this.$route.query.type);
      });
      this.$router.push({
        path: "/relation",
        query: { taskId: this.$route.query.id, type: 0 }
      });
    }
  }
};
</script>
<style scoped>
.zrw:hover {
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(250, 245, 245, 0.44);
}
/* 数字字符串换行 */
.huanghang {
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  /* width: 100%; */
}
.Ach {
  width: 100%;
  background-color: #eeeeee;
}
.Achievement {
  width: 1000px;
  /* border: 1px solid red; */
  /* display: inline-block; */
  margin: auto;
}
.Achievement_center {
  background-color: #ffffff;
  /* border: 1px solid red; */
  width: 100%;
}
.el-icon-s-home {
  font-size: 25px;
  color: #a5a5a5;
}
.Achievement_top {
  height: 66px;
  line-height: 66px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.center_top {
  width: 100%;

  /* height: 50px; */
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 50px;
  text-align: center;
  border-bottom: 2px solid #b5b5b5;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
}
.border {
  border-bottom: 2px solid #b5b5b5;
  border-left: 2px solid #b5b5b5;
  border-right: 2px solid #b5b5b5;
  width: 90%;
  margin: auto;
  position: relative;
  top: -3px;
  /* height:1000px;
  display: inline-block;
  overflow: hidden; */
  /* border: 1px solid red; */
}
.right {
  border-left: 2px solid #b5b5b5;
}
/* .xian{
  width: 2px;
  min-height: 100%;
  display: inline-block;
  background-color:red ;
} */
.left,
.right {
  width: 449px;
  height: 850px;
  /* border: 1px solid red; */
  display: inline-block;
  vertical-align: top;
  /* overflow: hidden; */
  /* position: fixed; */
  /* top: 0;
  bottom: 0; */
  /* overflow: scroll; */
}
.right {
  overflow: hidden;
}
.right_voer {
  width: 466px;
  height: 850px;
  overflow: auto;
}
.leftList {
  width: 449px;
  height: 770px;
  overflow: hidden;
}
.leftListNoneGd {
  width: 466px;
  height: 704px;
  overflow: auto;
  border-bottom: 2px solid #b5b5b5;
}
.subtask_btn {
  width: 430px;
  height: 60px;
  color: #fff;
  background-color: #409eff;
  text-align: center;
  line-height: 60px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}
.subtask_btn:hover {
  opacity: 0.9;
}
.btnWidth {
  width: 270px;
}
.subtask_img {
  width: 150px;
  height: 150px;
  background-color: #eee;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.left_name {
  height: 66px;
  background: #ffffff;
  border-bottom: 2px solid #b5b5b5;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 66px;
  text-align: center;
}
.zrw {
  /* width: 100%; */
  margin-bottom: 10px;
  cursor: pointer;
  border-bottom: 2px solid #b5b5b5;
  /* border-bottom: 2px solid #b5b5b5; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 42px;
  height: 42px;
  padding-left: 10px;
  padding-right: 10px;
}
.t_name {
  width: 300px;
  display: inline-block;
  /* border: 1px solid red; */
  margin-right: 20px;
}
.ts_ {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0084ff;
  /* float: right; */
  cursor: pointer;
  display: inline-block;
  /* border: 1px solid blue; */
  vertical-align: top;
  margin-left: 10px;
  margin-right: 10px;
}
.ts_1 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8b8f92;
  /* float: right; */
  cursor: pointer;
  display: inline-block;
  /* border: 1px solid blue; */
  vertical-align: top;
  margin-left: 10px;
  margin-right: 10px;
}
.name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 30px;
}
.name_font {
  font-size: 14px;
  font-family: Microsoft YaHei;
  /* font-weight: bold; */
  color: #000000;
  line-height: 30px;
}
.padin {
  /* overflow-y:scroll; */
  padding: 15px;
  border-bottom: 2px solid #b5b5b5;
  /* border-left: 2px solid #b5b5b5; */
}
.overflow {
  /* width: 200px; */
}
.padin2 {
  padding: 15px;
}
.fujian {
  width: 100%;
  box-sizing: border-box;
  /* display: inline-block; */
  /* border: 1px solid #b5b5b5; */
}
.enclosure {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  text-decoration: underline;
  color: #0084ff;
  line-height: 36px;
  margin-right: 12%;
  margin-left: 12%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  /* border:1px solid red; */
}
.wid {
  width: 100px;
  height: 40px;
  border: 1px solid #b5b5b5;
}
.bottom {
  background-color: #ffffff;
  height: 80px;
  padding-top: 40px;
  text-align: center;
} /* 弹框 */
.tankuang {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shensu {
  width: 450px;
  height: 226px;
  background-color: #fdfdfd;
  border: 1px solid #4b4a4a;
  /* position: relative; */
  /* z-index: -1; */
}
.heyue_top {
  width: 100%;
  /* height: 18px; */
  padding: 10px 0px 0px 0px;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: right;
} /* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  /* border: 1px solid #dcdcdc; */
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
.tyss {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 48px;
}
.pinjia {
  width: 35px;
  height: 35px;
}
ul li {
  width: 80px;
  float: left;
}
ul {
  width: 330px;
  padding-left: 120px;
  /* border:1px solid blue; */
  text-align: center;
  display: inline-block;
  height: 60px;
}
ul li span {
  /* border: 1px solid red; */
  width: 100%;
  display: inline-block;
  /* height:50px; */
  vertical-align: bottom;
  /* cursor: pointer; */
}
.v_top {
  position: relative;
  top: -20px;
}
.elbutton {
  border: 2px solid rgb(199, 199, 199);
}
.cursor {
  cursor: pointer;
}
.enclosure_content {
  font-size: 15px;
  line-height: 25px;
}
.enclosure_name {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  text-decoration: underline;
  color: #0084ff;
  line-height: 36px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
</style>